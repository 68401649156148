// import React, { useEffect, useState } from "react";
// import "./single.scss";
// import Sidebar from "../../components/sidebar/Sidebar";
// import Navbar from "../../components/navbar/Navbar";
// import Chart from "../../components/chart/Chart";
// import List from "../../components/table/Table";
// import useFetch from "../../hooks/useFetch";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import axios from "axios";
// import { useLocation, useNavigate } from "react-router-dom";
// import Form from "react-bootstrap/Form";
// import Getallteacher from "./Getallteacher";

// const Assign = (props) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const id = location.pathname.split("/")[3];
//   const code = location.pathname.split("/")[4];
//   // const nowid = location.state ? location.state.nowid : null; // Access state from location object
//   console.log("nowid nowid", props.nowid);
//   console.log("id", id);
//   const [teacherData, setTeacherData] = useState(null);
//   const { data, loading, error } = useFetch(
//     `https://api.studentrays.com/api/users/${id}`
//   );
//   const { data: eventResponse } = useFetch(
//     `https://api.studentrays.com/api/events/${data.teacherAssigned}`
//   );
//   const { data: teacherResponse } = useFetch(
//     `https://api.studentrays.com/api/users/${eventResponse.teacherid}`
//   );
//   // const eventResponse = await axios.get(`/events/${data.teacherAssigned}`);
//   console.log("teacherAssignedResponse", eventResponse);
//   console.log("teacherResponse", teacherResponse);
//   console.log("seeData", data);

//   return (
//     <div className="single">
//       <Sidebar />
//       <div className="singleContainer">
//         <Navbar />
//         <div className="top">
//           <div className="left">
//             {/* <div className="editButton">Edit</div> */}
//             <h1 className="title">Information</h1>
//             <div className="item">
//               <img
//                 // src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
//                 src={data.img}
//                 alt=""
//                 className="itemImg"
//               />
//               <div className="details">
//                 {/* <h1 className="itemTitle">Jane Doe</h1> */}
//                 <h1 className="itemTitle">{data.username}</h1>
//                 <div className="detailItem">
//                   <span className="itemKey">Email:</span>
//                   <span className="itemValue">{data.email}</span>
//                 </div>
//                 <div className="detailItem">
//                   <span className="itemKey">Phone:</span>
//                   <span className="itemValue">{data.phone}</span>
//                 </div>
//                 <div className="detailItem">
//                   <span className="itemKey">Grade:</span>
//                   <span className="itemValue">{data.grade}</span>
//                 </div>
//                 <div className="detailItem">
//                   <span className="itemKey">State:</span>
//                   <span className="itemValue">{data.state}</span>
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* <div className="right">
//             <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
//           </div> */}
//         </div>
//         <div className="bottom">
//           {/* {data && data.batch ? ( */}
//           {data && data.assignedid ? (
//             <span>
//               {data.assignedid.length > 0 ? (
//                 <div>
//                   <p>Current Student Batches:</p>
//                   <ul>
//                     {data.assignedid.map((item, index) => (
//                       <li key={index}>
//                         <b>Batch:</b> {item.batch} <br />
//                         <b>Assigned Teacher:</b>{" "}
//                         {item.teacherAssigned !== "empty"
//                           ? item.teacherAssigned
//                           : "Not Assigned Yet"}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               ) : (
//                 <p>No batches assigned yet.</p>
//               )}
//             </span>
//           ) : null}

//           <h2>Select Student Subject than Assign Teacher</h2>
//           <h3>Assign Teacher</h3>
//           {/* <button onClick={teacherFunction}>Click me</button> */}
//           {data && data.batch ? (
//             <Getallteacher id={id} data={data} batch={data.batch[0]} />
//           ) : null}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Assign;
import React, { useEffect, useState } from "react";
import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import useFetch from "../../hooks/useFetch";
import Getallteacher from "./Getallteacher";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
const Assign = (props) => {
  const [editingIndex, setEditingIndex] = useState(null); // Tracks which batch is being edited
  const [newBatch, setNewBatch] = useState("");
  const [newBatchid, setNewBatchid] = useState("");
  const [teacherData, setTeacherData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split("/")[3];
  const code = location.pathname.split("/")[4];

  const handleEditClick = (index, currentBatch, id) => {
    setEditingIndex(index);
    setNewBatch(currentBatch); // Set the input field with the current batch value
    setNewBatchid(id); // Set the input field with the current batch value
  };

  // Function to handle batch input change
  const handleBatchChange = (e) => {
    setNewBatch(e.target.value); // Update the new batch value as the user types
  };

  // Function to handle form submission (sending updated batch to the server)
  const handleSubmit = async (item) => {
    // Post the updated batch to the server here
    try {
      // console.log("newBatch", newBatch);
      // console.log("newBatchid", newBatchid);

      const response = await axios.put(
        `https://api.studentrays.com/api/users/batchupdate/${data._id}`,
        {
          newBatch: newBatch, // The new batch value
          newBatchid: newBatchid, // The _id of the element in the assignedid array to update
        }
      );

      console.log("Response:", response.data);

      if (response.status === 200) {
        // setEditingIndex(""); // Reset editing index on success
        // setNewBatch(newBatch);
        window.location.reload();
        console.log("Batch update successful.");
      } else {
        console.error("Failed to update batch:", response.status);
      }
    } catch (error) {
      console.error("Error updating batch:", error);
    }
  };
  // const handleBatchDelete = async (item) => {
  //   console.log("item",item);
    
    // try {

    //   const response = await axios.put(
    //     `https://api.studentrays.com/api/users/batchupdate/${data._id}`,
    //     {
    //       newBatch: newBatch, // The new batch value
    //       newBatchid: newBatchid, // The _id of the element in the assignedid array to update
    //     }
    //   );

    //   console.log("Response:", response.data);

    //   if (response.status === 200) {
    //     // setEditingIndex(""); // Reset editing index on success
    //     // setNewBatch(newBatch);
    //     window.location.reload();
    //     console.log("Batch update successful.");
    //   } else {
    //     console.error("Failed to update batch:", response.status);
    //   }
    // } catch (error) {
    //   console.error("Error updating batch:", error);
    // }
  // };

  console.log("nowid nowid", props.nowid);
  console.log("id", id);
  const { data, loading, error } = useFetch(
    `https://api.studentrays.com/api/users/${id}`
  );

  const { data: eventResponse } = useFetch(
    `https://api.studentrays.com/api/events/${data.teacherAssigned}`
  );
  const { data: teacherResponse } = useFetch(
    `https://api.studentrays.com/api/users/${eventResponse.teacherid}`
  );

  console.log("teacherAssignedResponse", eventResponse);
  console.log("teacherResponse", teacherResponse);
  console.log("seeData", data);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <h1 className="title">Information</h1>
            <div className="item">
              <img src={data.img} alt="" className="itemImg" />
              <div className="details">
                <h1 className="itemTitle">{data.username}</h1>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{data.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{data.phone}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Grade:</span>
                  <span className="itemValue">{data.grade}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">State:</span>
                  <span className="itemValue">{data.state}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom">
          {data && data.assignedid ? (
            <>
              <div>
                {data.assignedid.length > 0 ? (
                  <div>
                    <p>Current Student Batches:</p>
                    <ul>
                      {data.assignedid.map((item, index) => (
                        <li key={index}>
                          <b>Batch:</b>{" "}
                          {editingIndex === index ? (
                            <input
                              type="text"
                              value={newBatch}
                              onChange={handleBatchChange}
                            />
                          ) : (
                            item.batch
                          )}{" "}
                          {editingIndex === index ? (
                            <button onClick={() => handleSubmit(item)}>
                              Save
                            </button>
                          ) : (
                            <>
                            <button
                              onClick={() =>
                                handleEditClick(index, item.batch, item._id)
                              }
                            >
                              Edit
                            </button>
                            {/* <button onClick={() => handleBatchDelete(item)}>Delete Batch</button> */}
                          </>)}
                         
                          <br />
                          {/* <b>Batch:</b> {item.batch} edit <br /> */}
                          <b>Assigned Teacher:</b>{" "}
                          {item.teacherAssigned !== "empty"
                            ? item.teacherAssigned
                            : "Not Assigned Yet"}
                          {/* Render Assign Teacher Section for Each Batch */}
                          <h2>
                            Select Student Subject for {item.batch} and Assign
                            Teacher
                          </h2>
                          <h3>Assign Teacher</h3>
                          {/* Render Getallteacher component with the current batch */}
                          {data && item.batch ? (
                            <Getallteacher
                              id={id}
                              data={data}
                              batch={item.batch}
                            />
                          ) : null}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <p>No batches assigned yet.</p>
                )}
              </div>
              {/* <div>aaaaaaaaaaaaaa</div> */}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Assign;
