// import "./new.scss";
// import Sidebar from "../../components/sidebar/Sidebar";
// import Navbar from "../../components/navbar/Navbar";
// import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
// import { useState } from "react";
// import axios from "axios";

// const New = ({ inputs, title }) => {
//   const [file, setFile] = useState("");
//   const [info, setInfo] = useState({});

//   const handleChange = (e) => {
//     setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
//   };

//   const handleClick = async (e) => {
//     e.preventDefault();
//     const data = new FormData();
//     data.append("file", file);
//     data.append("upload_preset", "upload");
//     try {
//       // const uploadRes = await axios.post(
//       //   // "https://api.cloudinary.com/v1_1/lamadev/image/upload",
//       //   data
//       // );

//       // const { url } = uploadRes.data;
//       const { url } = data;

//       const newUser = {
//         ...info,
//         img: url,
//       };

//       await axios.post("https://api.studentrays.com/api/auth/register", newUser);
//       const baseUrl = window.location.origin;
//       const urlToNavigate = `${baseUrl}/users`;
//       window.location.href = urlToNavigate;
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   console.log(info);
//   return (
//     <div className="new">
//       <Sidebar />
//       <div className="newContainer">
//         <Navbar />
//         <div className="top">
//           <h1>{title}</h1>
//         </div>
//         <div className="bottom">
//           <div className="left">
//             <img
//               src={
//                 file
//                   ? URL.createObjectURL(file)
//                   : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
//               }
//               alt=""
//             />
//           </div>
//           <div className="right">
//             <form>
//               <div className="formInput">
//                 <label htmlFor="file">
//                   Image: <DriveFolderUploadOutlinedIcon className="icon" />
//                 </label>
//                 <input
//                   type="file"
//                   id="file"
//                   onChange={(e) => setFile(e.target.files[0])}
//                   style={{ display: "none" }}
//                 />
//               </div>

//               {inputs.map((input) => (
//                 <div className="formInput" key={input.id}>
//                   <label>{input.label}</label>
//                   <input
//                     onChange={handleChange}
//                     type={input.type}
//                     placeholder={input.placeholder}
//                     id={input.id}
//                   />
//                 </div>
//               ))}
//               <button onClick={handleClick}>Send</button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default New;
import React, { useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import axios from "axios";
import "./new.scss";

const New = ({ inputs, title }) => {
  const [file, setFile] = useState("");
  const [info, setInfo] = useState({});

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };
  const getbatch = (course, grade, subject, section) => {
    const batch = `${course}${grade}${subject}${section}`;
    if (batch) {
      return batch;
    }
  };
  const handleClick = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "upload");

    // Log the data
    for (let [key, value] of data.entries()) {
      console.log(`${key}:`, value);
    }

    // Extracting data
    const fileData = data.get("file");
    const uploadPreset = data.get("upload");

    console.log("Extracted File Data:", fileData);
    console.log("Extracted Upload Preset:", uploadPreset);
    try {
      const { url } = data;
      let newUser = { ...info, img: url, payment: true };
      let assignedData;
      // let newBatch;
      if (newUser.subject === "Both") {
        const matBatch = getbatch(
          newUser.course,
          newUser.grade,
          "mat",
          newUser.section
        );
        const sciBatch = getbatch(
          newUser.course,
          newUser.grade,
          "sci",
          newUser.section
        );
        // console.log("1",matBatch,sciBatch);
        // newBatch = { batchs: batchs };
        assignedData = [
          {
            batch: sciBatch,
            teacherAssigned: "", // Leave teacherAssigned empty
          },
          {
            batch: matBatch,
            teacherAssigned: "", // Leave teacherAssigned empty
          },
        ];
        // console.log("assignedData", assignedData);
        newUser = {
          ...info,
          img: url,
          payment: true,
          assignedid: assignedData,
        };
        console.log("newUser", newUser);
      } else {
        const batchs = getbatch(
          newUser.course,
          newUser.grade,
          newUser.subject,
          newUser.section
        );
        console.log("batchs", batchs);
        assignedData = [
          {
            batch: batchs,
            teacherAssigned: "", // Leave teacherAssigned empty
          },
        ];
        newUser = {
          ...info,
          img: url,
          payment: true,
          assignedid: assignedData,
        };
        console.log("newUser", newUser);
      }

      //   assignedid:[]         };
      await axios.post(
        "https://api.studentrays.com/api/auth/register",
        newUser
      );
      window.location.href = `${window.location.origin}/users`;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  {input.type === "select" ? (
                    <select
                      id={input.id}
                      value={info[input.id] || ""}
                      onChange={handleChange}
                    >
                      {input.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type={input.type}
                      placeholder={input.placeholder}
                      id={input.id}
                      onChange={handleChange}
                    />
                  )}
                </div>
              ))}
              <button onClick={handleClick}>Send</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default New;
