import React, { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import axios from "axios";
const Getallteacher = ({ id, data, batch }) => {
  const {
    data: teacherData,
    loading,
    error,
  } = useFetch(`https://api.studentrays.com/api/users/allteacher/${batch}`);
  console.log("datasssssssssss", teacherData);
  useEffect(() => {
    console.log("Getallteacher data", data);
    console.log("Getallteacher batch", batch);
    console.log("datas", teacherData);
  }, [teacherData]);

  const [joinUrl, setJoinUrl] = useState("");
  const [teacher_id, setTeacher_id] = useState("");
  // const [marker, setMarker] = useState();
  const [teacherUrl, setTeacherUrl] = useState("");
  const [batchId, setBatchId] = useState(batch);

  // const handleBatchIdChange = (event) => {
  //   setBatchId(event.target.value);
  // };

  const handleSelectChange = async (selectedValue, teacherId) => {
    try {
      console.log("Teacher ID:", teacherId);
      setTeacher_id(teacherId);
      console.log("Selected value:", selectedValue);

      // const eventResponse = await axios.get(
      //   `https://api.studentrays.com/api/calender/${teacherId}/${batchId}`
      // );
      // console.log("eventResponse", eventResponse);
      const response = await axios.put(
        `https://api.studentrays.com/api/calender/${teacherId}/${batch}/${data._id}`
      );
      console.log("calender response", response);
      // const dataToSend = {
      //   teacherAssigned: teacherId,
      // };
      const updatedAssignedId = data.assignedid.map((item) => {
        if (item.batch === batch) {
          // Add teacherId where the batch matches
          return {
            ...item,
            teacherAssigned: teacherId,
          };
        }
        return item; // Return unchanged if batch does not match
      });
  
      // Prepare the data to send with the updated assignedid
      const dataToSend = {
        assignedid: updatedAssignedId, // The updated array with teacherId
      };
      const newresponse = await axios.put(
        `https://api.studentrays.com/api/users/${data._id}`,
        dataToSend
      );
      console.log("calender user response", newresponse);
      window.alert(
        "Current Assigned teacher for this student is " + selectedValue
      );
      window.history.back();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <select
        className="assignButton"
        onChange={(e) => {
          const selectedTeacher = teacherData.find(
            (teacher) => teacher._id === e.target.value
          );
          handleSelectChange(selectedTeacher.username, e.target.value);
        }}
      >
        <option value="">select one</option>
        {teacherData &&
          teacherData.map((data, index) => (
            <option key={index} value={data._id}>
              {data.username}
            </option>
          ))}
      </select>
      <hr />
    </>
  );
};

export default Getallteacher;
